/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from 'react';
import './Tabs.scss';

import Pane from './Pane';

export default class Tabs extends React.Component {
  static Pane = Pane;

  render() {
    const { children, selectedIndex, height } = this.props;

    let currentPaneIndex = 0;
    const elsToRender = React.Children.map(children, child => {
      switch (child.type) {
        case Pane:
          const paneProps = {
            in: currentPaneIndex++ === selectedIndex,
            height,
            ...child.props
          };

          return React.cloneElement(child, paneProps);
        default:
          throw new Error(
            // eslint-disable-next-line prettier/prettier
            `Direct children of the Tabs component must be one of TabList or Pane; got ${child.type}`
          );
      }
    });

    return (
      <div className="Tabs Container" style={{ height }}>
        {elsToRender}
      </div>
    );
  }
}
