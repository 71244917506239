/* eslint-disable react/prop-types */
import React from 'react';
import './HeroVideo.scss';

export default function HeroVideo({
  src,
  children,
  imageSrc = null,
  backgroundColor = null,
  overlay = 'medium' // light | medium | dark
}) {
  const backgroundColorStyle = backgroundColor
    ? {
        backgroundColor
      }
    : null;

  const backgroundImageStyle = imageSrc
    ? {
        backgroundImage: `url(${imageSrc})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    : null;

  return (
    <div
      className={`HeroVideo Container Container-${overlay}`}
      style={{ ...backgroundColorStyle, ...backgroundImageStyle }}
    >
      <video className="Video" autoPlay muted loop src={src} />
      <div className="Children">{children}</div>
    </div>
  );
}
