/* eslint-disable react/prop-types */
import React from 'react';
import './TwoUp.scss';

export default function TwoUp({ children, wrap = true }) {
  return (
    <div className={`TwoUp Container ${wrap ? 'Wrap' : ''}`}>
      {React.Children.map(children, child => (
        <div className="Item" key={child}>
          {child}
        </div>
      ))}
    </div>
  );
}
