/* eslint-disable react/prop-types */
import React from 'react';
import './Header.scss';

export default function Header({
  children,
  size = 'large' // large | small
}) {
  return <h2 className={`Header Size-${size}`}>{children}</h2>;
}
