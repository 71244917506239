/* eslint-disable react/prop-types */

import React from 'react';
import './WithFloatingArrow.scss';

export default function WithFloatingArrow({ children }) {
  const floatingArrowMarkup = <span className="FloatingArrow">&rarr;</span>;

  return (
    <span className="FloatingArrowContainer">
      {typeof children === 'function' ? (
        children(floatingArrowMarkup)
      ) : (
        <span>
          {children}
          {floatingArrowMarkup}
        </span>
      )}
    </span>
  );
}
