/* eslint-disable react/prop-types */
import React from 'react';
import Item from './Item';
import './stack.scss';

function wrapItem(Component) {
  if (Component == null) {
    return null;
  }

  return Component.type === Item ? Component : <Item>{Component}</Item>;
}

export default class Stack extends React.PureComponent {
  static Item = Item;

  render() {
    const {
      children, // ReactNode
      vertical, // boolean
      spacing, // extraLoose | loose | comfortable | tight | none
      alignment, // leading | trailing | center | fill | baseline | justified-center
      distribution, // equalSpacing | leading | trailing | center | fill
      noWrap, // boolean
      className
    } = this.props;

    return (
      <div
        className={`
        Stack
        ${vertical ? `Vertical` : ''} 
        ${spacing ? `Spacing-${spacing}` : ''}
        ${distribution ? `Distribution-${distribution}` : ''}
        ${alignment ? `Alignment-${alignment}` : ''}
        ${noWrap ? `NoWrap` : ''}
        ${className || ''}
        `}
      >
        {React.Children.map(children, wrapItem)}
      </div>
    );
  }
}
