/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  Checkbox,
  LayoutUI,
  Stack,
  TextField,
  TextStyle,
  Button
} from '../../ui-kit';

import InstagramIcon from '../../assets/icons/instagram.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import LinkedInIcon from '../../assets/icons/linkedin.svg';

import './footer.scss';

const Footer = () => {
  const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/7208022/o52ul9g/';
  const [daily, setDaily] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [honeyPot, setHoneyPot] = useState('');

  const submitForm = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    if (honeyPot.length === 0) {
      fetch(ZAPIER_URL, {
        method: 'POST',
        body: data
      })
        .then(() => {
          setSuccessMessage(
            'Welcome! So happy to have you part of this community. Your vitamin for the soul is on it’s way! 😊'
          );
        })
        .catch(() => {
          setSuccessMessage(
            "Whoops! Something went wrong and we didn't catch that. 🙃 Refresh the page and try again. If it continues to not work, reach out at hello@projecthappiness.org. We'll get is squared away!"
          );
        });
    }
  };

  return (
    <footer className="Footer">
      <LayoutUI>
        <Stack spacing="loose" vertical>
          <div className="FooterTopContainer">
            <Stack vertical spacing="tight">
              <Link className="Link" to="/contact">
                Contact
              </Link>
              <Link className="Link" to="/faq">
                FAQs
              </Link>
              <Link className="Link" to="/about">
                About
              </Link>
            </Stack>
            <Stack spacing="tight">
              <a href="https://www.instagram.com/projecthappiness_org/">
                <img
                  src={InstagramIcon}
                  className="Icon"
                  alt="Project Happiness Instagram"
                />
              </a>
              <a href="https://twitter.com/projecthappy">
                <img
                  src={TwitterIcon}
                  className="Icon"
                  alt="Project Happiness Twitter"
                />
              </a>
              <a href="https://www.facebook.com/projecthappiness/">
                <img
                  src={FacebookIcon}
                  className="Icon"
                  alt="Project Happiness Facebook"
                />
              </a>
              <a href="https://www.youtube.com/user/projecthappiness">
                <img
                  src={YoutubeIcon}
                  className="Icon"
                  alt="Project Happiness Youtube"
                />
              </a>
              <a href="https://www.linkedin.com/company/project-happiness">
                <img
                  src={LinkedInIcon}
                  className="Icon"
                  alt="Project Happiness LinkedIn"
                />
              </a>
            </Stack>
            <div>
              <TextStyle color="red">
                {!successMessage ? (
                  <Stack vertical>
                    <p>
                      Sign up for our emails&mdash;
                      <br />
                      they will brighten your day!
                    </p>
                    <form
                      // action={ZAPIER_URL}
                      // method="post"
                      target="_blank"
                      id="footer_form"
                      // type="POST"
                      onSubmit={e => submitForm(e)}
                      // success_location="https://projecthappiness.org/newsletter-success/"
                    >
                      <div className="CheckboxContainer">
                      <Checkbox
                          checked={daily}
                          color="red"
                          inactiveState="red-outline"
                          label="Daily Inspiration"
                          name="daily"
                          className="inspiration"
                          onChange={() => setDaily(!daily)}
                        />
                        <Checkbox
                          checked={weekly}
                          color="red"
                          inactiveState="red-outline"
                          label="Weekly Re-Cap"
                          name="weekly"
                          className="recap"
                          onChange={() => setWeekly(!weekly)}
                        />
                        <Checkbox
                          checked={honeyPot}
                          color="red"
                          inactiveState="red-outline"
                          label="Honey Pot"
                          name="honeyPot"
                          className="ohnohoney"
                          onChange={() => setHoneyPot(!honeyPot)}
                        />
                      </div>
                      <div className="input__container">
                        <TextField
                          label="First Name"
                          color="red"
                          onChange={e => setFirstName(e.target.value)}
                          name="firstName"
                          value={firstName}
                        />
                        <TextField
                          label="Last Name"
                          color="red"
                          onChange={e => setLastName(e.target.value)}
                          name="lastName"
                          value={lastName}
                        />
                      </div>
                      <div className="input__container">
                        <TextField
                          // button="&rarr;"
                          label="email"
                          color="red"
                          onChange={e => setEmail(e.target.value)}
                          name="email"
                          value={email}
                        />
                        <Button primary color="red" type="submit">
                          Submit
                        </Button>
                      </div>
                    </form>
                  </Stack>
                ) : (
                  <p>{successMessage}</p>
                )}
              </TextStyle>
            </div>
          </div>

          <Stack>
            <p className="Copyright">
              &copy;
              {new Date().getFullYear()}
            </p>
            <Link className="SubduedLink" to="/terms">
              Terms
            </Link>
            <Link className="SubduedLink" to="/policy">
              Policy
            </Link>
          </Stack>
        </Stack>
      </LayoutUI>
    </footer>
  );
};

export default Footer;
