/* eslint-disable react/prop-types */
import React from 'react';
import './Button.scss';

export default function Button({
  children, // React.ReactNode
  color, // red | orange | yellow | yellow-dark | teal | blue | purple | gray
  href, // string
  secondary, // boolean
  onClick, // () => void
  ...props
}) {
  return href == null ? (
    <div className="button-component">
      <button
        type="button"
        className={`
        Button ${secondary ? 'Secondary' : ''}
        ${color ? `Color-${color}` : ''}
      `}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    </div>
  ) : (
    <div className="button-component">
      <a
        className={`
          Button ${secondary ? 'Secondary' : ''}
          ${color ? `Color-${color}` : ''}
        `}
        href={href}
        onClick={onClick}
        {...props}
      >
        {children}
      </a>
    </div>
  );
}
