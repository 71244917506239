/* eslint-disable react/prop-types */
import React from 'react';
import Transition from 'react-transition-group/Transition';
import './Tabs.scss';

const DURATION = 300;

const defaultStyle = {
  opacity: 0,
  position: 'absolute',
  transition: `opacity 300ms ease-in-out`
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

export default function Pane({ children, in: inProp, height }) {
  return (
    <Transition in={inProp} timeout={DURATION}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            height
          }}
          className="Tabs Pane"
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
