export default {
  BLACK: '#212B36',

  BLUE_LIGHT: '#AABCFF',
  BLUE: '#708EFF',
  BLUE_DARK: '#3D59C1',
  BLUE_DARKER: '#1D3796',

  GRAY_LIGHTEST: '#FAFAFA',
  GRAY_LIGHTER: '#F6F6F6',
  GRAY_LIGHT: '#E8E8E8',
  GRAY: '#C5C5C5',
  GRAY_DARK: '#9C9C9C',
  GRAY_DARKER: '#565A5C',

  ORANGE_LIGHT: '#FFBA4A',
  ORANGE: '#FB9A00',
  ORANGE_DARK: '#F78200',

  PURPLE_LIGHT: '#E8C7FF',
  PURPLE: '#D9A7FC',
  PURPLE_DARK: '#C679FC',

  RED_LIGHT: '#FF978A',
  RED: '#FF5B46',
  RED_DARK: '#F53219',

  TEAL_LIGHT: '#7EE9E2',
  TEAL: '#41CAC2',
  TEAL_DARK: '#369F9B',

  VIOLET_LIGHT: '#CDA7FC',
  VIOLET: '#9761DD',
  VIOLET_DARK: '#8441D4',

  YELLOW_LIGHTER: '#FFF9DD',
  YELLOW_LIGHT: '#FFF037',
  YELLOW: '#FFDA26',
  YELLOW_DARK: '#F0C500',
  YELLOW_DARKER: '#BB9A05',

  WHITE: '#FFF'
};
