/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Button from '../Button/Button';
import LayoutUI from '../Layout/Layout';
import Header from '../Header/Header';
import Stack from '../Stack/Stack';
import './FormSuccess.scss';

export default function FormSuccess({
  name,
  successPhrase = "Thank you for submitting your application! We'll be reaching out shortly.",
  href = '/home',
  buttonText = 'Go Home',
  color = 'red'
}) {
  // This is so the window is in the right position when it renders the component
  window.scrollTo(0, 0);

  return (
    <div className="FormSuccess">
      <LayoutUI narrow>
        <Stack vertical spacing="loose">
          <Header>
            Thank you,
            {name}!
          </Header>

          <Stack vertical spacing="extraLoose">
            <p>{successPhrase}</p>
            <Button href={href} color={color}>
              {buttonText}
            </Button>
          </Stack>
        </Stack>
      </LayoutUI>
    </div>
  );
}
