import React from 'react';
import Helmet from 'react-helmet';
import config from '../../data/SiteConfig';

import './layout.scss';

import Footer from './footer/footer';

/* eslint-disable */

const Layout = ({ location, children }) => (
  <>
    <Helmet>
      <title>{config.siteTitle}</title>
      {/* <link rel="canonical" href={location.href} /> */}

      {/* {metatags.map(({ key, value }) => {
        let tag;
        if (key === 'title') {
          tag = <title key={key}>{value}</title>;
        } else if (key === 'canonical') {
          tag = <link key={key} rel="canonical" href={value} />;
        } else if (tagsWithName.includes(key)) {
          tag = <meta key={key} name={key} content={value} />;
        } else {
          tag = <meta key={key} property={key} content={value} />;
        }

        return tag;
      })} */}
      <html lang="en" />
    </Helmet>
    
    <div>{children}</div>
    <Footer />
  </>
);

export default Layout;
