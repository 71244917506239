/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Subheader from '../Subheader';
import './Textarea.scss';

export default function Textarea({
  // eslint-disable-next-line react/prop-types
  label,
  name,
  onChange,
  value,
  isRequired = false
}) {
  const isRequiredMarkup = isRequired ? (
    <span className="Required">*</span>
  ) : null;

  const labelMarkup =
    label != null ? (
      <label>
        <Subheader>
          {label}
          {isRequiredMarkup}
        </Subheader>
      </label>
    ) : null;

  return (
    <div>
      {labelMarkup}
      <textarea
        className="Textarea"
        name={name}
        onChange={onChange}
        rows={5}
        value={value}
      />
    </div>
  );
}
