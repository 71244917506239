/* eslint-disable react/prop-types */
import React from 'react';
import './Subheader.scss';

export default function Subheader({
  children,
  size = 'normal' // small | normal | large
}) {
  return <h4 className={`Subheader Size-${size}`}>{children}</h4>;
}
