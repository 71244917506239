/* eslint-disable react/prop-types */
import React from 'react';

import './HeroImage.scss';

export default function HeroImage({
  children,
  height = 'medium', // large | medium | small | auto
  image, // An image, processed by webpack
  backgroundColor, // A color for the background of the Hero section
  overlay, // dark | light
  imageAlignment = 'center', // top | bottom | center
  noMargin // boolean - whether or not to add a margin under the image
}) {
  const backgroundImageCss = image ? { backgroundImage: `url(${image})` } : {};

  const backgroundColorCss = backgroundColor ? { backgroundColor } : {};

  return (
    <div
      className={`HeroImage
      Container
      Height-${height} 
      AlignImage-${imageAlignment} 
      ${noMargin ? 'NoMargin' : ''}
      ${overlay ? `Overlay-${overlay}` : ''}
      `}
      style={{
        ...backgroundColorCss,
        ...backgroundImageCss
      }}
    >
      <div className="Children">{children}</div>
    </div>
  );
}
