/* eslint-disable react/prop-types */
import React from 'react';
import Transition from 'react-transition-group/Transition';
import './Checkbox.scss';

import CheckmarkIcon from '../../../assets/icons/checkmark-white.svg';

const transitionStyles = {
  entering: { transform: 'scale(1)' },
  entered: { transform: 'scale(1)' },
  exiting: { transform: 'scale(0)' },
  exited: { transform: 'scale(0)' }
};

export default function Checkbox({
  checked = false,
  color = 'orange', // PH color
  inactiveState = 'default', // default | white | red-outline
  label,
  name,
  onChange,
  className
}) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`Checkbox-component Container ${className}`}>
      <input
        checked={checked}
        className="Input"
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <div
        className={`${
          checked ? `PseudoActive-${color}` : `Pseudo-${inactiveState}`
        }`}
      >
        <Transition in={checked} timeout={150}>
          {state => (
            <img
              alt=""
              className="Icon"
              src={CheckmarkIcon}
              style={transitionStyles[state]}
            />
          )}
        </Transition>
      </div>
      {label}
    </label>
  );
}
