/* eslint-disable react/prop-types */
import React from 'react';
import './ColoredSection.scss';

export default function ColoredSection({
  children,
  color, // orange-light | yellow-lighter | gray-lighter
  height = 'medium' // small | medium | large
}) {
  return (
    <div className={`ColoredSection-${color} ColoredSection-height-${height}`}>
      {children}
    </div>
  );
}
