/* eslint-disable react/prop-types */
import React from 'react';
import './Layout.scss';

export default function LayoutUI({ children, className, fullWidth, narrow }) {
  return (
    <div
      className={`Layout ${narrow ? 'Narrow' : ''} ${
        fullWidth ? 'FullWidth' : ''
      } ${!className ? '' : className}`}
    >
      {children}
    </div>
  );
}
