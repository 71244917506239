/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import Subheader from '../Subheader/Subheader';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-overrides.css';
import './DatePicker.scss';

export default class DatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  render() {
    const { date, label, onDateChange, isRequired = false } = this.props;

    const isRequiredMarkup = isRequired ? (
      <span className="DatePicker-required">*</span>
    ) : null;

    const innerLabelMarkup =
      label != null ? (
        <Subheader>
          {label}
          {isRequiredMarkup}
        </Subheader>
      ) : null;

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label>
        {innerLabelMarkup}
        <SingleDatePicker
          date={date}
          onDateChange={onDateChange}
          // eslint-disable-next-line react/destructuring-assignment
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          placeholder="&nbsp;"
          numberOfMonths={1}
        />
      </label>
    );
  }
}
