/* eslint-disable react/prop-types */
import React from 'react';
import './TextStyle.scss';

export default function TextStyle({
  children, // ReactNode
  color, // Any valid PH color
  center // boolean
}) {
  return (
    <span
      className={`${color ? `text-color-${color}` : ''} ${
        center ? 'Center' : ''
      }`}
    >
      {children}
    </span>
  );
}
