/* eslint-disable react/prop-types */
import React from 'react';
import './stack.scss';

export default function Item({
  children, // ReactNode
  fill, // boolean
  fullWidth // boolean
}) {
  return (
    <div
      className={`
      Item
      ${fill ? `Item-fill` : ''}
      ${fullWidth ? `Item-fullWidth` : ''}
      `}
    >
      {children}
    </div>
  );
}
