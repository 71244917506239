/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Stack from '../Stack/Stack';

import './FollowBar.scss';

import FollowImage from '../../../assets/images/shared/follow.svg';
import InstagramIcon from '../../../assets/icons/instagram-follow.svg';
import TwitterIcon from '../../../assets/icons/twitter-follow.svg';
import FacebookIcon from '../../../assets/icons/facebook-follow.svg';
import LinkedinIcon from '../../../assets/icons/linkedin-follow.svg';

export default function FollowBar({
  instagram = null,
  facebook = null,
  twitter = null,
  linkedin = null,
  website = null
}) {
  return (
    (instagram || facebook || twitter || linkedin || website) && (
      <div className="FollowBar">
        <Stack vertical spacing="none">
          <Stack distribution="center" alignment="center" spacing="tight">
            <img
              className="BioFollowImage"
              src={FollowImage}
              alt="Follow me on social media"
            />
            {facebook && (
              <a href={facebook}>
                <img src={FacebookIcon} alt="Facebook" />
              </a>
            )}
            {instagram && (
              <a href={instagram}>
                <img src={InstagramIcon} alt="Instagram" />
              </a>
            )}
            {twitter && (
              <a href={twitter}>
                <img src={TwitterIcon} alt="Twitter" />
              </a>
            )}
            {linkedin && (
              <a href={linkedin}>
                <img src={LinkedinIcon} alt="LinkedIn" />
              </a>
            )}
          </Stack>
          {website && (
            <p className="Website">
              <a href={website}>{website}</a>
            </p>
          )}
        </Stack>
      </div>
    )
  );
}
