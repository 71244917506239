/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React from 'react';
import Subheader from '../Subheader';
import './TextField.scss';

export default function TextField({
  button, // string - the content of the ajoined button
  color, // PH color - the border color of the input
  label, // string
  labelSize, // array
  name, // string
  onChange, // function(): void
  onClick, // function(): void - the event to be fired when the button is clicked
  value, // string
  isRequired = false // bool
}) {
  const isRequiredMarkup = isRequired ? (
    <span className="Required">*</span>
  ) : null;

  const labelMarkup =
    label != null ? (
      <label>
        <Subheader size={labelSize}>
          {label}
          {isRequiredMarkup}
        </Subheader>
      </label>
    ) : null;

  const buttonMarkup =
    button != null ? (
      <button type="button" className="Button" onClick={onClick}>
        {button}
      </button>
    ) : null;

  return (
    <div className="TextField Container">
      {labelMarkup}
      <input
        name={name}
        onChange={onChange}
        type="text"
        value={value}
        className={`Input ${button ? 'HasButton' : ''} ${
          color ? `Input-${color}` : ''
        }`}
      />
      {buttonMarkup}
    </div>
  );
}
