module.exports = {
  pathPrefix: '/',
  siteTitle: 'Project Happiness', // Site title.
  siteTitleAlt:
    'Enabling small businesses to make it big by design, developing and marketing.', // Alternative site title for SEO.
  // siteLogo:
  // '../src/assets/images', // Logo used for SEO and manifest.
  siteUrl: 'https://www.projecthappiness.org', // Domain of your website without pathPrefix.
  siteDescription:
    'Enabling small businesses to make it big by design, developing and marketing.',
  // metaImage:
  //   '',
  companyStructuredData: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Project Happiness',
    url: 'https://www.projecthappiness.org'
    // logo:
    //   '',
  }
};
