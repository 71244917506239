/* eslint-disable react/prop-types */
import React from 'react';
import './LeadText.scss';

export default function LeadText({
  children,
  alignment = 'center' // center | left
}) {
  return <p className={`LeadText Align-${alignment}`}>{children}</p>;
}
